import React, { useState, useEffect } from "react"
import axios from "axios"

import "./upload.scss"

import Layout from "../components/layout/layout"
import UploadInput from "../components/documents/uploadInput"
import guardianCounselApi from "../apis/guardianCounselApi"
import { Link } from "gatsby"

const UploadPage = () => {
  const [errors, setErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [uploadState, setUploadState] = useState("ready")
  const [currentDocument, setCurrentDocument] = useState("")

  const fileState = useState([])
  const [files, setFiles] = fileState

  const uploadMore = () => {
    setUploadState("ready")
    setFiles([])
  }

  const beginUploads = async () => {
    var isValid = validateForm()
    setIsSubmitted(true)

    if (!isValid) {
      return
    }

    setUploadState("uploading")

    var isValid = true

    for (var idx = 0; idx < files.length; idx++) {
      var formData = new FormData()

      formData.append("file", files[idx])
      setCurrentDocument(files[idx].name)

      try {
        await guardianCounselApi.put("/documents/v2", formData)
      } catch (err) {
        setErrors({
          error:
            "We've encountered an issue when trying to process your files. Our IT have been notified, please try again later.",
        })
        setUploadState("apiError")
        isValid = false
      }
    }

    if (isValid) {
      setCurrentDocument("")
      setUploadState("uploaded")
    }
    setIsSubmitted(false)
  }

  useEffect(() => {
    if (isSubmitted) {
      validateForm()
    }
  }, [files, isSubmitted])

  const validateForm = () => {
    let newErrors = { count: 0 }

    if (!files.length) {
      newErrors["files"] = "Please choose at least one file to upload."
      newErrors.count++
    }
    for (var fileIdx = 0; fileIdx < files.length; fileIdx++) {
      const file = files[fileIdx]
      const sizeOk = validateFilesize(file.size)
      const extOk = validateFilename(file.name)

      if (!sizeOk) {
        newErrors[`files[${fileIdx}]`] =
          "This file is too large. Please contact us directly so we can use a different method of file submission."

        newErrors.count++
      }
      if (!extOk) {
        newErrors[`files[${fileIdx}]`] =
          "This file type is not supported at this time."
        newErrors.count++
      }
    }

    setErrors(newErrors)
    return newErrors.count === 0
  }

  return (
    <>
      <Layout>
        <br />

        <h2 style={{ marginBottom: "25px" }}>Secure Document Upload</h2>
        <p>
          <Link to="/dashboard" className="btn btn-success">
            Back to Dashboard
          </Link>
        </p>

        <hr />
        {uploadState === "apiError" && (
          <>
            <div style={{ color: "pink" }}>
              We've encountered an issue when trying to process your files. Our
              IT team has been notified; please try again later.
            </div>
          </>
        )}
        {uploadState === "uploading" && <>Uploading {currentDocument}...</>}
        {uploadState === "uploaded" && (
          <div className="full-width-centered">
            Documents received! Thank you.
            <br />
            <div>
              <br />
              <div className="btn btn-info" onClick={uploadMore}>
                Upload More Documents
              </div>
            </div>
          </div>
        )}
        {uploadState === "ready" && (
          <>
            <div className="field">
              <UploadInput fileState={fileState} />
            </div>

            {errors["files"] && (
              <div className="full-width-centered contact-error">
                {errors["files"]}
              </div>
            )}
            <UploadedFilesList errors={errors} files={files} />

            <div className="full-width-centered" style={{ marginTop: "25px" }}>
              {errors.count > 0 && (
                <div className="full-width-centered contact-error">
                  Please correct the errors above before submitting your files.
                </div>
              )}
              <br />
              <div className="btn btn-info" onClick={beginUploads}>
                Submit Files
              </div>
            </div>
          </>
        )}
        <br />
      </Layout>
    </>
  )
}

const UploadedFilesList = ({ files, errors }) => {
  if (!files.length) {
    return <></>
  }
  return (
    <div className="file-list">
      <div className="full-width-centered">
        File{files.length > 1 ? "s" : ""} you've selected:
      </div>
      <br />
      {files.map((f, idx) => (
        <UploadedFilesListItem
          error={errors[`files[${idx}]`]}
          key={f.lastModified}
          file={f}
        />
      ))}
    </div>
  )
}

const UploadedFilesListItem = ({ file, error }) => {
  const icon = getIconForFilename(file.name)

  let className = "file-list-item"

  if (error) {
    className += " has-error"
  }

  return (
    <div>
      <div className={className}>
        <div className="file-status">
          <div className="file-name full-width-centered">
            <i className={`fa fa-${icon} icon`} />
            {file.name} <br />
            {error}
          </div>
          <div className="file-progress"></div>
        </div>
        <div className="file-actions"></div>
      </div>
    </div>
  )
}

const getIconForFilename = (filename) => {
  const fileParts = filename.split(".")
  const ext = fileParts[fileParts.length - 1]

  switch (ext) {
    case "pdf":
      return "file-pdf-o"
    case "doc":
    case "docx":
      return "file-word-o"
    case "xls":
    case "xlsx":
      return "file-excel-o"
    case "txt":
      return "file-text-o"
    default:
      return "file"
  }
}

const validateFilesize = (fileSize) => {
  return fileSize < 3500000
}

const validateFilename = (filename) => {
  const fileParts = filename.split(".")
  const ext = fileParts[fileParts.length - 1]
  const validExts = ["pdf", "doc", "docx", "xls", "xlsx", "txt"]

  return true // validExts.includes(ext)
}

export default UploadPage
