import React from "react"

const UploadInput = ({ fileState }) => {
  const [, setFiles] = fileState

  const handleFileUpload = (e) => {
    let newFiles = []
    for (let idx = 0; idx < e.target.files.length; idx++) {
      newFiles.push(e.target.files[idx])
    }
    setFiles(newFiles)
  }

  return (
    <>
      <div className="upload-container">
        <span className="upload-title">
          Use the "Choose Files..." button below to select files you want to
          upload, then click "Submit Files".
        </span>

        <br />
        <br />

        <div style={{ width: "100%", textAlign: "center" }}>
          <label htmlFor="file" className="upload-label">
            <span>
              <i className="fa fa-upload" /> Choose Files...
            </span>
            <input
              id="file"
              type="file"
              multiple
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </label>
        </div>
      </div>
    </>
  )
}

export default UploadInput
